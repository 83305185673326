import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import img_bg from "../../img/R-BG.png";
import img_logo from "../../img/H-logo.png";

import LoadingIcons from "react-loading-icons";

import { UserContext } from "../../context/UserContext";

export default function VerifyCode() {
  const navigate = useNavigate();

  const { user, setUser } = useContext(UserContext);

  const [code, setCode] = useState("");

  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    setError(""); // Clear any previous error messages
    await fetch(
      "https://australia-southeast1-smart-ticket-394606.cloudfunctions.net/smart-ticket-prod-check-token",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ phoneNumber: user.phone, verifyCode: code }),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then(async (data) => {
        setUser({ ...user, status: "verified", token: data.token });
        await fetch(
          "https://australia-southeast1-smart-ticket-394606.cloudfunctions.net/harmony-capture-signer",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              phone: user.phone,
              name: user.name,
              email: user.email,
            }),
          }
        );
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError("Invalid code. Please try again.");
      });
  };

  useEffect(() => {
    if (user.status === "verified") {
      navigate("/");
    }
  }, [user, navigate]);

  return (
    <div>
      {/* <img src={img_bg} alt="bg" className="w-screen h-screen" /> */}

      <div
        className="absolute top-0 left-0 w-screen h-screen"
        style={{
          width: "full",
          backgroundImage: `url(${img_bg})`,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "50px",
          paddingTop: "20px",
        }}
      >
        {/* <div className="">
        </div> */}
        {loading ? (
          <div className="absolute top-0 left-0 w-screen h-screen bg-black bg-opacity-50 flex flex-col justify-center items-center">
            <LoadingIcons.Bars className="text-white h-20" />
            <p className="text-white mt-10">Loading...</p>
          </div>
        ) : (
          <div className="flex mt-36">
            <div className="m-auto">
              <img
                src={img_logo}
                alt="logo"
                className="h-32 mb-4 mx-auto cursor-pointer animate__animated animate__slower 2s animate__pulse animate__infinite	infinite"
                onClick={() => {
                  window.location.href = "/";
                }}
              />

              <div className="mt-4">
                <label className="text-white" for="username">
                  Mobile
                </label>

                <div className="border-1 bg-white px-1 py-1 mt-2 rounded-lg opacity-70">
                  <PhoneInput
                    inputStyle={{
                      //   width: "screen",
                      borderStyle: "none",
                      outline: "none",
                      fontSize: "15px",
                    }}
                    buttonStyle={{
                      borderStyle: "none",
                      outline: "none",
                      backgroundColor: "transparent",
                    }}
                    dropdownStyle={{ width: "15rem" }}
                    searchStyle={{ width: "12rem" }}
                    disabled
                    country={"au"}
                    enableSearch={true}
                    value={user.phone}
                    // onChange={(phone) => setPhone(`+${phone}`)}
                    onChange={(phone) =>
                      setUser({ ...user, phone: `+${phone}` })
                    }
                  />
                </div>
              </div>
              <p
                for="link-checkbox"
                className="text-xs font-medium text-gray-900 dark:text-gray-300 text-justify mt-2"
              >
                Please check your SMS for the verification code.
              </p>
              <div className="mt-5">
                <label className="text-white" for="username">
                  Code
                </label>
                <input
                  id="name"
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring"
                  onChange={(e) => setCode(e.target.value)}
                />
              </div>

              <button
                id="name"
                type="text"
                onClick={handleSubmit}
                className="block w-full px-4 py-2 mt-10 text-gray-800 bg-[#FFA700] rounded-md font-bold  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring"
              >
                Verify
              </button>
              {error && (
                <p className="text-center text-sm text-white mt-3">{error}</p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
