import logo from "./logo.svg";
import "./App.css";

import { Routes, Route, useParams, BrowserRouter } from "react-router-dom";
import HarmonyPage from "./pages/harmony";
import NdaChecklist from "./pages/nda-checklist";

import { UserContext, UserProvider } from "./context/UserContext";
import VerifyCode from "./pages/verify-code";
import IssuuIframe from "./pages/issuu-iframe";

function App() {
  return (
    <BrowserRouter>
      <UserProvider>
        <Routes>
          <Route path="/" element={<HarmonyPage />} />
          <Route path="/nda" element={<NdaChecklist />} />
          <Route path="/verify" element={<VerifyCode />} />
          <Route path="/document/:documentId" element={<IssuuIframe />} />
        </Routes>
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
