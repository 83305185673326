import React, { createContext, useState, useEffect } from "react";
import Cookies from "js-cookie";

export const UserContext = createContext();

export function UserProvider({ children }) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(() => {
    const userCookie = Cookies.get("user");
    return userCookie
      ? JSON.parse(userCookie)
      : {
          phone: "",
          name: "",
          email: "",
          status: "",
          token: "",
        };
  });

  useEffect(() => {
    Cookies.set("user", JSON.stringify(user), { expires: 30 });
  }, [user]);

  useEffect(() => {
    const userCookie = Cookies.get("user");
    if (userCookie) {
      setUser(JSON.parse(userCookie));
    }
    setLoading(false);
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, loading }}>
      {children}
    </UserContext.Provider>
  );
}
