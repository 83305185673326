import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import img_bg from "../../img/R-BG.png";
import img_logo from "../../img/H-logo.png";

import LoadingIcons from "react-loading-icons";

import { UserContext } from "../../context/UserContext";

export default function NdaChecklist() {
  const navigate = useNavigate();

  const { user, setUser } = useContext(UserContext);

  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);

  const [signed, setSigned] = useState(false);

  const [password, setPassword] = useState("");

  // console.log(phone);

  const handleSubmit = () => {
    setLoading(true);
    setError(""); // Clear any previous error messages
    if (signed === false) {
      setError("Please agree to conditions above.");
      setLoading(false);
      return;
    } else {
      fetch(
        "https://australia-southeast1-smart-ticket-394606.cloudfunctions.net/smart-ticket-prod-send-token",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          // body: JSON.stringify({ phoneNumber: phone }),
          body: JSON.stringify({
            phoneNumber: user.phone,
            name: user.name,
            email: user.email,
          }),
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          console.log("Success:", data);
          setLoading(false);
          Cookies.set("user", JSON.stringify(user), { expires: 30 });

          alert("Verification code sent!");

          navigate("/verify");
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error:", error);
          setError("An error occurred. Please try again.");
        });
    }
  };

  const sessionToken = Cookies.get("session_token");
  useEffect(() => {
    if (sessionToken) {
      console.log("sessionToken", sessionToken);
      navigate("/");
    }
  }, [sessionToken, navigate]);

  if (sessionToken) {
    return null;
  }

  return (
    <div>
      {/* <img src={img_bg} alt="bg" className="w-screen h-screen" /> */}

      <div
        className="absolute top-0 left-0 w-screen h-screen"
        style={{
          width: "full",
          backgroundImage: `url(${img_bg})`,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "50px",
          paddingTop: "20px",
        }}
      >
        {/* <div className="">
        </div> */}
        {loading ? (
          <div className="absolute top-0 left-0 w-screen h-screen bg-black bg-opacity-50 flex flex-col justify-center items-center">
            <LoadingIcons.Bars className="text-white h-20" />
            <p className="text-white mt-10">Loading...</p>
          </div>
        ) : (
          <div className="flex mt-36">
            <div className="m-auto">
              <img
                src={img_logo}
                alt="logo"
                className="h-32 mb-4 mx-auto cursor-pointer animate__animated animate__slower 2s animate__pulse animate__infinite	infinite"
                onClick={() => {
                  window.location.href = "/";
                }}
              />
              <div className="">
                <label className="text-white" for="username">
                  Name
                </label>
                <input
                  id="name"
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring"
                  onChange={(e) => setUser({ ...user, name: e.target.value })}
                />
              </div>
              <div className="mt-4">
                <label className="text-white" for="username">
                  Email (Work email)
                </label>
                <input
                  id="email"
                  type="email"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring"
                  onChange={(e) => setUser({ ...user, email: e.target.value })}
                />
              </div>
              <div className="mt-4">
                <label className="text-white" for="username">
                  Mobile
                </label>

                <div className="border-1 bg-white px-1 py-1 mt-2 rounded-lg">
                  <PhoneInput
                    inputStyle={{
                      //   width: "screen",
                      borderStyle: "none",
                      outline: "none",
                      fontSize: "15px",
                    }}
                    buttonStyle={{
                      borderStyle: "none",
                      outline: "none",
                      backgroundColor: "transparent",
                    }}
                    dropdownStyle={{ width: "15rem" }}
                    searchStyle={{ width: "12rem" }}
                    country={"au"}
                    enableSearch={true}
                    // value={phone}
                    // onChange={(phone) => setPhone(`+${phone}`)}
                    onChange={(phone) =>
                      setUser({ ...user, phone: `+${phone}` })
                    }
                  />
                </div>
              </div>

              <div className="mt-4">
                <label className="text-white" for="username">
                  Password (ask your Nimble contact for the password)
                </label>
                <input
                  id="password"
                  type="password"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div className="flex items-center mt-10 max-w-lg">
                <input
                  id="link-checkbox"
                  type="checkbox"
                  onClick={() => setSigned(!signed)}
                  value=""
                  className="w-4 h-4 text-blue-600 rounded focus:ring-blue-600 ring-offset-gray-800 focus:ring-2 bg-gray-700 border-gray-600"
                />
                <label
                  for="link-checkbox"
                  className="ms-2 text-xs font-medium text-gray-300 text-justify"
                >
                  By entering your information you agree to the terms and
                  conditions attached
                  <a
                    href="/docs/NDA_Nimble-Estates-2023.pdf"
                    target="_blank"
                    className="text-blue-500 hover:underline"
                  >
                    {" "}
                    here
                  </a>
                  . Please do not contact council without prior written approval
                  from the vendor .
                </label>
              </div>

              <button
                id="name"
                type="text"
                onClick={() => {
                  if (password === "Nimble2023") {
                    handleSubmit();
                  } else {
                    setError("Incorrect password");
                  }
                }}
                className="block w-full px-4 py-2 mt-2 text-gray-800 bg-[#FFA700] rounded-md font-bold  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring"
              >
                Agree & proceed
              </button>
              {error && (
                <p className="text-center text-sm text-white mt-3">{error}</p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
