import React from "react";
import { useParams } from "react-router-dom";

const IssuuIframe = () => {
  const { documentId } = useParams();

  if (!documentId) {
    return <div>No document ID provided</div>;
  }

  const issuuUrl = `https://e.issuu.com/embed.html?d=${documentId}&u=web4.com.au&hideIssuuLogo=true&hideShareButton=true&pageLayout=singlePage`;

  return (
    <div className="w-full h-screen">
      <iframe
        className="w-full h-full"
        src={issuuUrl}
        allowFullScreen
        title="Issuu Document"
      ></iframe>
    </div>
  );
};

export default IssuuIframe;
